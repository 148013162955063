import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            license_num: "",
            name: "",
            address1: "",
            address2: "",
            city: "",
            province: "ON",
            postal_code: "",
            account_type: 1,
            account_type_name: "On-Premise",
            account_types: {
                0: "Special",
                1: "On-Premise",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                9: "MLSB PP",
                10: "Convenience Store",
            },
            processing: false
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    changeAccountType = (account_type) => {
        var account_type_name = this.state.account_types[account_type]
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        });
    }

    addAccount = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/account', this.state)
            .then(function (response) {
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
                self.props.goToAccount(response.data.account_id);
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <FormGroup className="m-0">
                <Button color="primary" size="xs" onClick={this.toggle}><i className="fas fa-plus"></i> Account</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Account</div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>License Number*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="license_num" value={this.state.license_num} onChange={(event) => this.updateField("license_num", event)}/>
                                    <small className="text-muted">Individual license numbers should start with "LSL"</small>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Account Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="name" value={this.state.name} onChange={(event) => this.updateField("name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Address 1*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="address1" value={this.state.address1} onChange={(event) => this.updateField("address1", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Address 2</Label>
                                <Col sm={8}>
                                    <Input type="text" name="address2" value={this.state.address2} onChange={(event) => this.updateField("address2", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>City*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="city" value={this.state.city} onChange={(event) => this.updateField("city", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Province*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="province" value={this.state.province} onChange={(event) => this.updateField("province", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Postal Code*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="postal_code" value={this.state.postal_code} onChange={(event) => this.updateField("postal_code", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Account Type</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                            {this.state.account_type_name}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem divider/>
                                            {Object.keys(this.state.account_types).map((key, i) =>
                                                <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addAccount}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add Account</Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        );
    };
}

export default (AddAccount);
