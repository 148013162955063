import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Col,
    Card,
    CardBody,
    FormGroup,
    Row,
    Alert
} from 'reactstrap';
import {getOwners} from '../Owners/OwnerFunctions.js';
import {getBrands} from '../Brands/BrandFunctions.js';
import {getTypes} from '../Types/Functions.js';
import {getFactors} from '../Common/Factors.js';
import {getCategories} from '../Categories/CategoryFunctions.js';

import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListsOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            new: {
                type: {
                    "name": ""
                },
                brand: {
                    "name": ""
                },
                owner: {
                    "name": "",
                    "email": "",
                    "address": ""
                },
                factor: {
                    "name": "",
                    "hectilitre": ""
                },
                category: {
                    "name": ""
                },
            },
            // default lists
            list: {
                type: [],
                brand: [],
                owner: [],
                factor: [],
                category: [],
            },
            functions: {
                "owner": {
                    "title": "Companies",
                    "description": "Names of companies that own brands. Used for splitting invoices."
                },
                "brand": {
                    "title": "Brands",
                    "description": "Brand family (e.g. Budweiser). Can contain one, or multiple products (e.g. Bud, Bud Light)"
                },
                "type": {
                    "title": "Product Types",
                    "description": "High level category for which products are placed (.e.g. Beer, Wine, Bar Tools)"
                },
                "category": {
                    "title": "Categories",
                    "description": "Low level SKU category to describe the pack type (.e.g. Keg, Case, Glassware)"
                },
                "factor": {
                    "title": "Factor/ Pack Size",
                    "description": "Available pack sizes or factors, and their respective volume (.e.g. 50L Keg, 24x473ml)"
                },
            }
        };
    }

    componentDidMount() {
        document.title = "Lists | Equals Brewing";
        this.loadData();
    }

    loadData() {
        var self = this;
        getOwners(function (owners) {
            var list = self.state.list;
            list.owner = owners;
            getBrands(function (brands) {
                list.brand = brands;
                getTypes(function (types) {
                    list.type = types;
                    getFactors(function (factor) {
                        list.factor = factor;
                        getCategories(function (category) {
                            list.category = category;
                            self.setState(
                                {list: list, loading: false}
                            );
                        });
                    })
                });
            });
        });
    }

    onchangeEdit = (name, id) => event => {
        var list = this.state.list;
        list[name][id][event.target.name] = event.target.value;
        this.setState({
            list: list
        });
    };

    onchangeNew = (name) => event => {
        var list = this.state.new;
        list[name][event.target.name] = event.target.value;
        this.setState({
            product: list
        });
    };

    saveChanges = (name) => {
        var error = "";
        var self = this;
        Object.keys(this.state.list[name]).map(function (entry) {
            if (self.state.list[name][entry].name == "") {
                error = "Please ensure no fields are blank";
            }
        });
        if (error == "") {
            var self = this;
            self.setState({loading: true});
            axios.defaults.withCredentials = true;
            axios.put(API_ROOT + '/' + name, {data: self.state.list[name]})
                .then(function (response) {
                    self.loadData();
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", "It didn't work", "error");
                });
        } else {
            Swal("Error", error, "error");
        }
    };

    addItem = (name) => {
        if (this.state.new[name] == "") {
            Swal("Error", "Field can't be blank", "error");
        } else {
            var self = this;
            self.setState({loading: true});
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/' + name, {data: self.state.new[name]})
                .then(function (response) {
                    var new_a = self.state.new;
                    new_a[name].name = "";
                    new_a[name].hectilitre = 0;
                    new_a[name].email = "";
                    new_a[name].address = 0;
                    self.setState({
                        new: new_a
                    });
                    self.loadData();
                    toast.success("Item Added", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", "It didn't work", "error");
                });
        }
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Product List
                    </div>
                </div>
                <Alert color="info" className="text-center">
                    Use this page to add and rename product attribute options. Note that once added, an item can not be deleted and should not be repurposed.
                </Alert>
                <div className={(typeof this.props.match.params.list_name != "undefined" ? "text-bold text-center pb-3" : "d-none")}>
                    <Link to="../../lists">Show All Lists</Link>
                </div>
                <Row className="justify-content-md-center">
                    {Object.keys(this.state.functions).map(function (name, key) {
                        var style = "";
                        if (typeof this.props.match.params.list_name != "undefined" && this.props.match.params.list_name != name) {
                            style = "d-none";
                        }
                        return (
                            <Col key={key} className={style} style={{"minWidth": "250px", "maxWidth": "500px"}}>
                                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                                    <CardBody>
                                        <p className="lead mb-0">
                                            {this.state.functions[name].title}
                                        </p>
                                        <div className="bb mb-3 pb-1 text-muted">
                                            {this.state.functions[name].description}
                                        </div>
                                        <FormGroup className="mb-2">
                                            <Input type="text" name="name" placeholder="Enter New Name" value={this.state.new[name].name} onChange={this.onchangeNew(name)}/>
                                            <InputGroup className={(name != "factor" ? "d-none" : "")}>
                                                <Input type="text" name="hectilitre" placeholder="Hectolitres" value={this.state.new[name].hectilitre}
                                                       onChange={this.onchangeNew(name)}/>
                                                <InputGroupAddon size="sm" addonType="prepend">
                                                    <InputGroupText>HL</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <div className={(name != "owner" ? "d-none" : "")}>
                                                <Input type="email" name="email" placeholder="Email (Order Notifications)" value={this.state.new[name].email}
                                                       onChange={this.onchangeNew(name)}/>
                                                <Input type="text" name="address" placeholder="Mailing Address (Cheques)" value={this.state.new[name].address}
                                                       onChange={this.onchangeNew(name)}/>
                                            </div>
                                        </FormGroup>
                                        <Button color="primary" size="sm" className="mt-1" onClick={() => this.addItem(name)}>Add</Button>
                                        <div className="border-top mt-2 pb-2"></div>
                                        {Object.keys(this.state.list[name]).map(function (entry, key2) {
                                            return (
                                                <FormGroup className="mb-2">
                                                    <Input type="text" name="name" key={key2} value={this.state.list[name][entry].name} onChange={this.onchangeEdit(name, entry)}/>
                                                    <InputGroup className={(name != "factor" ? "d-none" : "pb-1")}>
                                                        <Input type="text" name="hectilitre" placeholder="Hectolitres" value={this.state.list[name][entry].hectilitre}
                                                               onChange={this.onchangeEdit(name, entry)}/>
                                                        <InputGroupAddon size="sm" addonType="prepend">
                                                            <InputGroupText>HL</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <div className={(name != "owner" ? "d-none" : "pb-1")}>
                                                        <Input type="email" name="email" placeholder="Email (Order Notifications)" value={this.state.list[name][entry].email}
                                                               onChange={this.onchangeEdit(name, entry)}/>
                                                        <Input type="text" name="address" placeholder="Mailing Address (Cheques)" value={this.state.list[name][entry].address}
                                                               onChange={this.onchangeEdit(name, entry)}/>
                                                    </div>
                                                </FormGroup>
                                            )
                                        }, this)}
                                        <Button color="primary" size="sm" className="mt-1" onClick={this.saveChanges.bind(this, name)}>Save Changes</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    }, this)}
                </Row>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default (ListsOverview);