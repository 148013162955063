import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class DeliveryList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            transactions: [],
            loading: true,
            // default search settings
            inventory_source_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            inventory_source_print: "All",
            // data
            inventory_sources: [],
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Delivery List | Equals Brewing";
        var self = this;
        getInventorySources(null, function (inventory_sources) {
            self.setState({
                inventory_sources: inventory_sources
            });
            self.getTransactions();
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getTransactions()
            });
        }
    };

    changeInventory = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var inventory_source_print = "All"
        } else {
            var inventory_source_print = this.state.inventory_sources[inventory_source_id].name
        }
        this.setState({
            inventory_source_id: inventory_source_id,
            inventory_source_print: inventory_source_print
        }, () => {
            this.getTransactions()
        });
    };

    goToDelivery = (order_id) => {
        this.props.history.push("/delivery/" + order_id);
    };

    refreshSearch = event => {
        this.getTransactions();
    };

    getTransactions() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + "/delivery/list?inventory_source_id=" + self.state.inventory_source_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate)
            .then(function (response) {
                self.setState({
                    transactions: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Delivery&nbsp;/&nbsp;List
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color="primary" caret style={{width: 'auto'}}>
                            Delivery By: {this.state.inventory_source_print}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeInventory(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                return (
                                    <DropdownItem key={inventory_source_id}
                                                  onClick={() => this.changeInventory(inventory_source_id, 1)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                )
                            }, this)}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="card-title">{this.state.transactions.length} Matching Deliveries</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 && !this.state.loading ? "table table-hover table-pointer" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-xl-table-cell">Delivery ID</th>
                                        <th>Date</th>
                                        <th>Delivery By</th>
                                        <th>Stops</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (delivery, key) {
                                        if (delivery.max_status == 0) {
                                            var status_print = "Processing";
                                            var status_css = "pink";
                                        } else if (delivery.max_status != delivery.min_status) {
                                            var status_print = "Mixed";
                                            var status_css = "secondary";
                                        } else if (delivery.max_status == 1) {
                                            var status_print = "Scheduled";
                                            var status_css = "purple";
                                        } else if (delivery.max_status == 2) {
                                            var status_print = "Out for Delivery";
                                            var status_css = "info";
                                        } else if (delivery.max_status == 3) {
                                            var status_print = "Delivered";
                                            var status_css = "success";
                                        } else if (this.props.shipping_status == 9) {
                                            var status_print = "Held - Pending Approval";
                                            var status_css = "dark";
                                        } else {
                                            var status_print = "Unknown/Error";
                                            var status_css = "danger";
                                        }
                                        return (
                                            <tr key={key} onClick={this.goToDelivery.bind(this, delivery.delivery_batch_id)}>
                                                <td className="d-none d-xl-table-cell">{delivery.delivery_batch_id}</td>
                                                <td>{delivery.date_submitted}</td>
                                                <td>{this.state.inventory_sources[delivery.inventory_source_id].name}</td>
                                                <td>{delivery.num_orders}</td>
                                                <td>
                                                    <div className={"badge badge-" + status_css}>{status_print}</div>
                                                </td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (DeliveryList);
