import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, UncontrolledButtonDropdown, InputGroup, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {getStaff} from '../Staff/StaffFunctions.js';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

const {
    Map,
    Marker,
    InfoWindow,
    withScriptjs,
    withGoogleMap,
    StyledMapWithAnInfoBox,
    GoogleMap,
} = require("react-google-maps");
const {InfoBox} = require("react-google-maps/lib/components/addons/InfoBox");

moment.tz.setDefault("America/Toronto");

class MapSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.updateDates = this.updateDates.bind(this);

        this.state = {
            accounts: [],
            pings: [],
            staff: [],
            loading: true,
            account_rep: (localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep') : 0),
            account_rep_name: (localStorage.getItem('account_rep_name') != null ? localStorage.getItem('account_rep_name') : "All"),
            activeMarker: {},
            selectedPlace: {},
            calendarEvents: [],
            showingInfoWindow: false,
            startDate: moment().endOf('day'),
            endDate: moment().add(7, 'day'),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Next 7 Days': [moment().endOf('day'), moment().add(7, 'day')],
                'All Upcoming': [moment().endOf('day'), moment().add(365, 'day')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Schedule Map | Equals Brewing";
        var self = this;
        getStaff(function (staff) {
            staff[0] = {
                'first_name': 'All',
                'last_name  ': ''
            };
            self.setState({
                staff: staff,
            });
            self.update();
        });
    }

    update() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/call_notes/summary?rep=' + this.state.account_rep + "&startDate=" + moment(this.state.startDate).format('YYYY-MM-DD') + "&endDate=" + moment(this.state.endDate).format('YYYY-MM-DD'))
            .then(function (response) {
                self.setState({
                    calendarEvents: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
    }

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.update()
        });
    };

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.update()
            });
        }
    };

    goToLink = (url) => {
        this.props.history.push(url);
    };

    refreshSearch = event => {
        this.update();
    };

    // map specific
    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
    };

    render() {
        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Schedule Map</div>
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <InputGroup direction="down">
                        <UncontrolledButtonDropdown direction="down">
                            <DropdownToggle direction="down" color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Rep: <b>{this.state.account_rep_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                    if (this.state.staff[sales_rep_id].status == 1) {
                                        return (<DropdownItem key={sales_rep_id}
                                                              onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                    }
                                })}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")} style={{"height": '100vh'}}>
                    <div style={{height: '100vh', width: '100%'}}>
                        <MapWithAMakredInfoWindow3
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAeOjO5jj-fhGU9So1rtJESjbuTh-6gJkg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                            markers={this.state.calendarEvents}
                            pings={this.state.pings}
                            staff={this.state.staff}
                            goToLink={this.goToLink}
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export class Map3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened_marker: false
        };
    }

    onToggleOpen = function (account_id) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.scrollTo(0, 200);
        }
        if (this.state.opened_marker == account_id) {
            account_id = false;
        }
        this.setState({
            opened_marker: account_id
        });
    };

    render() {
        return (
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{lat: 43.651890, lng: -79.381706}}
                options={{fullscreenControl: true, gestureHandling: 'greedy'}}
            >
                {this.props.markers.map(function (marker) {
                    console.log(marker)
                    var html = '<br><p><b>' + moment(marker['start']).format("ddd MMM d H:mm A") + '</b> to <b>' + moment(marker['end']).format("ddd MMM d H:mm A") + '</b></p><p><b>Notes:</b><br>';
                    html += marker['notes'];
                    html += '</p>';
                    var unknown_location = false;
                    if (isNaN(marker.lat)) {
                        marker.lat = "43.802620";
                        marker.lng = "-79.619247";
                        unknown_location = true;
                    }
                    if (marker.sales_rep_id == null) {
                        marker.sales_rep_id = 0;
                    }
                    var marker_url = "../img/markers/spotlight-poi2_hdpi_" + marker.sales_rep_id + ".png";
                    var directions_url = "https://www.google.com/maps/dir/?api=1&destination=" + marker.address1 + ", " + marker.city + ", Ontario&layer=t";
                    return (
                        <Marker
                            position={{lat: marker.lat, lng: marker.lon}}
                            key={marker.id}
                            onClick={this.onToggleOpen.bind(this, marker.account_id)}
                            icon={{
                                url: marker_url,
                                anchor: new window.google.maps.Point(15, 32),
                                scaledSize: new window.google.maps.Size(27, 43)
                            }}
                        >
                            {this.state.opened_marker == marker.account_id && <InfoWindow key={marker.account_id}>
                                <div style={{fontSize: '14px'}}>
                                    <div className="h4 mb-1">{marker.title}</div>
                                    <div className={(unknown_location ? "text-danger h4 mb-o" : "d-none")}>(Map Location Unknown)</div>
                                    <div>{marker.address1}{(marker.address2 != null ? " " + marker.address2 : "")}, {marker.city}</div>
                                    <div dangerouslySetInnerHTML={{__html: html}}/>
                                    <div>
                                        <div className="btn btn-xs btn-primary"
                                             onClick={this.props.goToLink.bind(this, "/account/" + marker.account_id + '/call/' + marker.id)}>ST {marker.account_id}</div>
                                        <a href={directions_url} target="_blank" className="btn btn-xs btn-info float-right"><i className="fa fa-location-arrow"></i> Directions</a>
                                    </div>
                                </div>
                            </InfoWindow>}
                        </Marker>
                    )
                }, this)}
                {this.props.pings.map(function (marker) {
                    var marker_url = "../img/markers/spotlight-poi2_hdpi_" + marker.staff_id + ".png";
                    var unknown_location = false;
                    if (isNaN(marker.latitude)) {
                        marker.latitude = "43.802620";
                        marker.longitude = "-79.619247";
                        unknown_location = true;
                    }
                    if (this.props.inventory_source_id == null || this.props.inventory_source_id == marker.staff_id) {
                        return (
                            <Marker
                                position={{lat: marker.latitude, lng: marker.longitude}}
                                key={marker.inventory_source_id}
                                onClick={this.onToggleOpen.bind(this, marker.inventory_source_id)}
                                icon={{
                                    url: marker_url,
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(14, 43),
                                    scaledSize: new window.google.maps.Size(27, 43)
                                }}
                                animation={window.google.maps.Animation.BOUNCE}
                            >
                                {this.state.opened_marker == marker.inventory_source_id && <InfoWindow key={marker.inventory_source_id}>
                                    <div style={{fontSize: '14px'}}>
                                        <div className={(unknown_location ? "text-danger h4 mb-o" : "d-none")}>(Unknown Location)</div>
                                        <div className="h4 mb-1">{this.props.inventory_sources[marker.inventory_source_id].name} Location</div>
                                        As of {marker.last_time}<br/>
                                        Accuracy: {marker.accuracy}m<br/>
                                        Speed: {marker.speed}
                                        {this.props.account_rep}
                                    </div>
                                </InfoWindow>}
                            </Marker>
                        )
                    }
                }, this)}
            </GoogleMap>
        );
    }
}

export const MapWithAMakredInfoWindow3 = withScriptjs(withGoogleMap(Map3));

export default (MapSchedule);
