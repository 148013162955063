import React, {Component} from "react";
import {Button, Form} from "react-bootstrap";
import ContentWrapper from '../Layout/ContentWrapper';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class ImportPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null, // the uploaded CSV file
            data: null, // the parsed data as an array
        };
        this.inputRef = React.createRef(); // a reference to the file input element
    }

    // a method to handle the file change event
    handleFileChange = (e) => {
        const file = e.target.files[0]; // get the file object
        if (file) {
            this.setState({
                file: file,
            });
        }
    };


    // a method to handle the file upload button click
    handleUpload = () => {
        let regex = /("(?:[^"\\]|\\.)*")|[^,]+/g;
        const {file} = this.state; // get the file from the state
        if (!file) return alert("Please select a file"); // check if the file is valid
        const reader = new FileReader(); // create a file reader
        reader.onload = (e) => {
            // when the file is loaded
            const text = e.target.result; // get the file content as text
            const lines = text.split("\n"); // split the text by line breaks
            const headers = lines[0].split(","); // get the headers from the first line
            const rows = lines.slice(1); // get the rows from the rest of the lines
            const array = rows.map((row) => {
                // map each row to an object
                if (row.match(regex) != null) {
                    var matches = row.match(regex);
                    var values = matches.map(match => match.replace(/^"|"$/g, '').replace(",", ""));
                    const object = headers.reduce((obj, header, i) => {
                        // create an object with header-value pairs
                        if (typeof values[i] != "undefined") {
                            obj[header.replace(/\r/g, "").replace("'", "")] = values[i];
                        }
                        return obj;
                    }, {});
                    return object; // return the object for each row
                }
            });

            axios.defaults.withCredentials = true;
            axios
                .post(API_ROOT + '/import_payments/', array) // send a post request with the data
                .then((response) => {
                    // handle the response
                    if (response.data.error) {
                        Swal("Error", response.data.error + ". " + response.data.payments_added + " payments added. Already marked as paid: " + response.data.already_paid, "error");
                    } else {
                        Swal("Done", response.data.payments_added + " payments added. Already marked as paid: " + response.data.already_paid, "success");
                    }
                })
                .catch((error) => {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false,
                            });
                        }
                    }
                });
        };
        reader.readAsText(file); // read the file as text
    };


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Import Payments
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-body">
                        Use this page to import a list of orders to be marked as paid. Uploaded file should be in CSV format, with the first column being the order number (named
                        "Order"), and the second column being the payment amount to apply (named "Total"). Payments will be recorded as being made by ETF.

                        <div className="pt-3 pb-2">
                            <input
                                type="file"
                                accept=".csv"
                                ref={this.inputRef}
                                onChange={this.handleFileChange}
                                className="form-controol"
                            />
                        </div>
                        <button onClick={this.handleUpload} className="btn btn-primary">Upload</button>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default ImportPayments;