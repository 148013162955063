import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    DropdownItem,
    DropdownMenu,
    UncontrolledButtonDropdown,
    DropdownToggle,
    ModalFooter,
    Table,
    Input
} from 'reactstrap';

import 'react-dropzone-uploader/dist/styles.css'
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

class OrderReturn extends React.Component {
    constructor(props) {
        super(props);
        //window.OrderReturn = this;

        this.state = {
            modal: false,
            return_date: moment().subtract(30, 'm').toDate(),
            order_id: false,
            account_id: false,
            delivery_instructions: "",
            notes: "",
            inventory_source_name: "",
            inventory_source_id: 0,
            inventory_sources: [],
            payment_method: 0,
            items: [],
            total: 0,
            subtotal: 0,
            tax: 0,
            deposit: 0,
            responses: {},
            editable: true,
            processing: false,
            payment_methods: {0: "Account Credit", 1: "Credit Card", 3: "ETF", 4: "Cheque", 5: "Cash",},
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.payment_method !== this.props.payment_method) {

            var new_items = [];
            for (let i = 0; i < this.props.items.length; i++) {
                let item = JSON.parse(JSON.stringify(this.props.items[i]));
                item['per_item'] = (item['subtotal'] / item['quantity']);
                item['deposit_rate'] = (item['deposit'] / item['quantity']);
                item['tax_rate'] = (item['tax'] / item['subtotal']);
                item['per_item_original'] = item['per_item'];
                item['quantity_original'] = item['quantity'];
                item['tax_original'] = item['tax'];
                item['deposit_original'] = item['deposit'];
                item['quantity'] = 0;
                item['tax'] = 0;
                item['deposit'] = 0;
                item['subtotal'] = 0;
                new_items.push(item);
            }

            var inventory_sources = this.props.inventory_sources;
            inventory_sources[0] = {inventory_source_id: 0, name: "No Returned Product / Written Off"};
            console.log(inventory_sources)
            this.setState({
                inventory_sources: inventory_sources,
                inventory_source_name: inventory_sources[this.props.inventory_source_id].name,
                inventory_source_id: this.props.inventory_source_id,
                payment_method: (this.props.payment_method == 2 ? 0 : this.props.payment_method), // if COD, set default to account credit
                items: new_items,
                account_id: this.props.account_id,
                order_id: this.props.order_id,
            });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateAmount = (name, key, event) => {
        var items = this.state.items;
        items[key][name] = event.target.value;
        if (name == "per_item" || name == "quantity") {
            items[key]['subtotal'] = (items[key]['per_item'] * items[key]['quantity']);
            items[key]['tax'] = (items[key]['subtotal'] * items[key]['tax_rate']);
            items[key]['deposit'] = (items[key]['quantity'] * items[key]['deposit_rate']);
        }
        this.setState({
            items: items
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    changePaymentMethod = (payment_method) => {
        this.setState({
            payment_method: payment_method,
        });
    };

    changeInventorySource = (inventory_source_id) => {
        this.setState({
            inventory_source_id: inventory_source_id,
            inventory_source_name: this.state.inventory_sources[inventory_source_id].name
        });
    };


    addCreditMemo = (editable) => {
        var self = this;
        self.setState({
            processing: true
        });
        axios.defaults.withCredentials = true;
        let data = this.state;
        data['staff_id'] = localStorage.getItem('staff_id')

        // add it
        axios.post(API_ROOT + '/credit_memo', data)
            .then(function (response) {
                self.props.history.push("/order/" + response.data.order_id);
                self.setState({
                    modal: false
                });
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        var total = 0;
        return (
            <span>
                <Button color="warning" className="ml-1" onClick={this.toggle}><i className="fas fa-undo-alt"></i> Return</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">Create Credit Memo / Return</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row pb-3">
                            <div className="col-3">Date & Time:</div>
                            <div className="col-9">
                                <DatePicker
                                    selected={this.state.return_date}
                                    wrapperClassName="border-left border-top border-right"
                                    onChange={date => this.updateField('return_date', date)}
                                    showTimeSelect
                                    disabled={!this.state.editable}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperModifiers={{
                                        preventOverflow: {
                                            enabled: true,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-3">Credit Method:</div>
                            <div className="col-9">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="secondary" caret>
                                        {this.state.payment_methods[this.state.payment_method]}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(this.state.payment_methods).map(function (payment_method, key) {
                                            return (
                                                <DropdownItem key={payment_method}
                                                              onClick={() => this.changePaymentMethod(payment_method)}>{this.state.payment_methods[payment_method]}</DropdownItem>
                                            )
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <div className={(this.state.payment_method != 1 ? "d-none" : "text-warning small")}><i className="fas fa-exclamation-circle"></i> Credit card refunds must be processed back to cardholder <b>manually</b>.</div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-3">Inventory Returned To:</div>
                            <div className="col-9">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="secondary" caret>
                                        {this.state.inventory_source_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                            return (
                                                <DropdownItem key={inventory_source_id}
                                                              onClick={() => this.changeInventorySource(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                            )
                                        }, this)}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-3">
                                External Notes:
                                <div className="small text-muted">(Visible to customer)</div>
                            </div>
                            <div className="col-9">
                                <Input type="textarea" style={{height: '60px'}} name="delivery_instructions" value={this.state.delivery_instructions}
                                       onChange={(event) => this.updateField("delivery_instructions", event)}/>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-3">
                                Internal Notes:
                                <div className="small text-muted">(Not visible to customer)</div>
                            </div>
                            <div className="col-9">
                                <Input type="textarea" style={{height: '60px'}} name="notes" value={this.state.notes} onChange={(event) => this.updateField("notes", event)}/>
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Price/Each</th>
                                <th>Return Qty.</th>
                                <th>Subtotal</th>
                                <th className="d-none d-sm-table-cell">+Deposit</th>
                                <th className="d-none d-sm-table-cell">+Tax</th>
                                <th className="d-none d-sm-table-cell">=Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.items.map(function (item, key) {
                                total += (parseFloat(item.subtotal) + parseFloat(item.tax) + parseFloat(item.deposit));
                                return (
                                    <tr key={key}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            <Input size="sm" type="text" name="per_item" title={"Original Order: $" + item.per_item_original} value={item.per_item}
                                                   onChange={(event) => this.updateAmount("per_item", key, event)}/>
                                        </td>
                                        <td>
                                            <Input size="sm" type="number" name="quantity" title={"Original Order: $" + item.quantity_original} value={item.quantity}
                                                   onChange={(event) => this.updateAmount("quantity", key, event)}/>
                                        </td>
                                        <td>${Number(item.subtotal).toLocaleString("en-US")}</td>
                                        <td className="d-none d-sm-table-cell">
                                            <Input size="sm" type="text" name="deposit" title={"Original Order: $" + item.deposit_original} value={item.deposit}
                                                   onChange={(event) => this.updateAmount("deposit", key, event)}/>
                                        </td>
                                        <td className="d-none d-sm-table-cell">
                                            <Input size="sm" type="text" name="tax" title={"Original Order: $" + item.tax_original} value={Number(item.tax).toLocaleString("en-US")}
                                                   onChange={(event) => this.updateAmount("tax", key, event)}/>
                                        </td>
                                        <td className="d-none d-sm-table-cell">${Number(parseFloat(item.subtotal) + parseFloat(item.tax) + parseFloat(item.deposit)).toLocaleString("en-US")}</td>
                                    </tr>
                                )
                            }, this)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="text-right" colSpan="6">Total Credit Memo:</th>
                                    <th>${total.toLocaleString("en-US")}</th>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className={(total > this.props.total ? "alert alert-danger" : "d-none")}>
                            <i className="fas fa-exclamation-circle"></i> <b>Warning:</b> The total Credit Memo exceeds the original order amount (${this.props.total}). You might be crediting too much.
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={(total == 0) ? true : false} onClick={() => this.addCreditMemo(0)}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>
                            Create Credit Memo
                        </Button>
                    </ModalFooter>
                </Modal>
            </span>
        );
    };
}

export default (OrderReturn);
