import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import EditStaff from '../Staff/EditStaff';
import AddStaff from '../Staff/AddStaff';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'moment-timezone';
import {getOwners} from "../Owners/OwnerFunctions";
import {getStaff} from "./StaffFunctions";

moment.tz.setDefault("America/Toronto");

class ListStaff extends React.Component {
    constructor(props) {
        super(props);

        this.loadStaff = this.loadStaff.bind(this);

        this.state = {
            loading: true,
            // default search settings
            product_id: null,
            // default search filter names
            product_name: "All",
            staff: [],
            companies: [],
            permissions: [
                {code: 'OH', name: "Order History"},
                {code: 'DM', name: "Delivery Map"},
                {code: 'AC', name: "Accounts"},
                {code: 'LC', name: "LCBO Stores"},
                {code: 'NS', name: "NSLC Stores"},
                {code: 'BO', name: "BOLs"},
                {code: 'IN', name: "Inventory"},
                {code: 'PP', name: "Product Pickup"},
                {code: 'SC', name: "Schedule"},
                {code: 'KE', name: "Kegs"},
                {code: 'PO', name: "Products"},
                {code: 'US', name: "Users"},
                {code: 'ST', name: "Staff"},
                {code: 'AL', name: "Accounting Log"},
                {code: 'TB', name: "TBS Report"},
                {code: 'AAC', name: "Add Account Credit"},
                {code: 'IP', name: "Import Payments"},
            ]
        };
    }

    componentDidMount() {
        document.title = "Staff Manager | Equals Brewing";
        var self = this;
        getOwners(function (owners) {
            self.setState(
                {
                    companies: owners,
                }, () => {
                    self.loadStaff();
                });
        });
    }

    loadStaff() {
        this.setState({loading: true});
        var self = this;
        getStaff(function (staff) {
            self.setState(
                {
                    staff: staff,
                    loading: false,
                });
        });
    }

    changeStatus = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.updateStaff();
        });
    };


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Staff Manager
                    <div className="ml-auto">
                        <AddStaff loadStaff={this.loadStaff} permissions={this.state.permissions} companies={this.state.companies}/>
                    </div>
                </div>
                <div className="text-center alert alert-info">Please note that staff changes will take effect on their next login</div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-hover " : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Company(s)</th>
                                        <th>Permission(s)</th>
                                        <th>Inventory</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(this.state.staff).map(function (staff_id, key) {
                                        var staff = this.state.staff[staff_id];
                                        if (staff.status == 1) {
                                            return (
                                                <tr key={key}>
                                                    <td><EditStaff staff={staff} loadStaff={this.loadStaff} permissions={this.state.permissions} companies={this.state.companies}/>
                                                    </td>
                                                    <td>{staff.email}</td>
                                                    <td><span
                                                        className={(staff.status == 1 || staff.status === true ? "badge badge-success" : "badge badge-danger")}>{(staff.status == 1 || staff.status === true ? "Active" : "Disabled")}</span>
                                                    </td>
                                                    <td>
                                                        {Object.keys(this.state.companies).map(function (company_id) {
                                                            if (staff.companies != null && staff.companies.indexOf(',' + company_id + ',') != -1) {
                                                                return (
                                                                    <span key={company_id}>{this.state.companies[company_id].name}, </span>
                                                                )
                                                            }
                                                        }, this)}
                                                        {(staff.companies == "ALL" ? "ALL" : "")}
                                                    </td>
                                                    <td>
                                                        {this.state.permissions.map(function (permission) {
                                                            if (staff.permissions != null && staff.permissions.indexOf(',' + permission.code + ',') != -1) {
                                                                return (
                                                                    <span key={permission.code}>{permission.name}, </span>
                                                                )
                                                            }
                                                        }, this)}
                                                        {(staff.permissions == "ALL" ? "ALL" : "")}
                                                    </td>
                                                    <td>
                                                        <i className={(staff.inventory_source_id > 0 ? 'fas fa-check text-success' : 'fas fa-times text-danger')}></i>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (ListStaff);
