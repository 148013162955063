import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, UncontrolledButtonDropdown, InputGroup, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';

const {
    Map,
    Marker,
    InfoWindow,
    withScriptjs,
    withGoogleMap,
    StyledMapWithAnInfoBox,
    GoogleMap,
} = require("react-google-maps");
const {InfoBox} = require("react-google-maps/lib/components/addons/InfoBox");

moment.tz.setDefault("America/Toronto");

class MapOrder extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            orders: [],
            pings: [],
            loading: true,
            inventory_source_id: (localStorage.getItem('inventory_source_id') != null ? localStorage.getItem('inventory_source_id') : null),
            delivery_status: (localStorage.getItem('delivery_status_map') != null ? localStorage.getItem('delivery_status_map') : 'not_delivered'),
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : null),
            startDate: moment("01 Jun 2018"),
            endDate: moment().endOf('day'),
            order_type: (localStorage.getItem('order_type') != null ? localStorage.getItem('order_type') : 1),
            // default search filter names
            delivery_status_name: (localStorage.getItem('delivery_status_name') != null ? localStorage.getItem('delivery_status_name') : "All"),
            order_type_name: (localStorage.getItem('order_type_name') != null ? localStorage.getItem('order_type_name') : "Real"),
            delivery_method_name: (localStorage.getItem('delivery_method_name') != null ? localStorage.getItem('delivery_method_name') : "All"),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "All"),

            account_types: {
                0: "Special",
                1: "On-Premise",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                9: "MLSB PP",
                10: "Convenience Store",
                99: "Retail & OP"
            },
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery"},
            order_types: {0: "Test", 1: "Real"},
            inventory_sources: [],
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false
        };
    }

    componentDidMount() {
        document.title = "Delivery Map | Equals Brewing";
        var self = this;
        getInventorySources('can_deliver', function (inventory_sources) {
            inventory_sources['0'] = {
                'inventory_source_id': 0,
                'name': '<Not Assigned>'
            };
            self.setState({
                inventory_sources: inventory_sources,
            });
            self.getOrders();
        });
        setInterval(async () => {
            this.getOrders();
        }, 60 * 1000 * 5);
    }

    getOrders() {
        this.setState({
            loading: true
        });
        var self = this;
        var delivery_status = "not_delivered";
        if (this.state.delivery_status != null) {
            delivery_status = this.state.delivery_status;
        }
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + "/orders/detail?payment_status=2&shipping_status=" + delivery_status + "&inventory_source_id=" + self.state.inventory_source_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&account_type=" + self.state.account_type)
            .then(function (response) {

                // get bols
                axios.get(API_ROOT + "/bols/detail?shipping_status=" + delivery_status + "&inventory_source_id=" + self.state.inventory_source_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&order_type=" + self.state.order_type + "&account_type=" + self.state.account_type)
                    .then(function (response_bol) {
                        self.setState({
                            orders: response.data.concat(response_bol.data),
                            loading: false,
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                orders: [],
                                loading: false
                            });
                        }
                    });

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
        axios.get(API_ROOT + "/pings/summary")
            .then(function (response) {
                self.setState({
                    pings: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    //
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getOrders()
            });
        }
    }

    changeDeliveryMethod = (inventory_source_id) => {
        if (inventory_source_id == null) {
            var delivery_method_name = "All"
        } else {
            var delivery_method_name = this.state.inventory_sources[inventory_source_id].name
        }
        localStorage.setItem('inventory_source_id', inventory_source_id);
        localStorage.setItem('delivery_method_name', delivery_method_name);
        this.setState({
            inventory_source_id: inventory_source_id,
            delivery_method_name: delivery_method_name
        }, () => {
            this.getOrders()
        });
    }

    changeDeliveryStatus = (delivery_status) => {
        if (delivery_status == null) {
            var delivery_status_name = "not_delivered"
        } else {
            var delivery_status_name = this.state.delivery_statuses[delivery_status]
        }
        localStorage.setItem('delivery_status_map', delivery_status);
        localStorage.setItem('delivery_status_name', delivery_status_name);
        this.setState({
            delivery_status: delivery_status,
            delivery_status_name: delivery_status_name
        }, () => {
            this.getOrders()
        });
    };

    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type_name', account_type_name);
        localStorage.setItem('account_type', account_type);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getOrders()
        });
    }

    changeOrderType = (order_type) => {
        if (order_type == null) {
            var order_type_name = "All"
        } else {
            var order_type_name = this.state.order_types[order_type]
        }
        localStorage.setItem('order_type', order_type);
        localStorage.setItem('order_type_name', order_type_name);
        this.setState({
            order_type: order_type,
            order_type_name: order_type_name
        }, () => {
            this.getOrders()
        });
    };


    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    refreshSearch = event => {
        this.getOrders();
    };


    // map specific
    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
    };

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Delivery Map</div>
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn d-none d-sm-block" color="primary">
                            Order Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Delivery By: <b>{this.state.delivery_method_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeDeliveryMethod(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.inventory_sources).map((inventory_source_id, key) =>
                                    <DropdownItem key={inventory_source_id}
                                                  onClick={() => this.changeDeliveryMethod(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="d-none d-sm-block">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Delivery Status: <b>{this.state.delivery_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeDeliveryStatus('not_delivered')}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.delivery_statuses).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeDeliveryStatus(key)}>{this.state.delivery_statuses[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="d-none d-sm-block">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Account Type: <b>{this.state.account_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_types).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="d-none d-sm-block">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.order_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeOrderType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.order_types).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeOrderType(key)}>{this.state.order_types[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")} style={{"height": '100vh'}}>
                    <div style={{height: '100vh', width: '100%'}}>
                        <MapWithAMakredInfoWindow2
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAeOjO5jj-fhGU9So1rtJESjbuTh-6gJkg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                            markers={this.state.orders}
                            pings={this.state.pings}
                            inventory_sources={this.state.inventory_sources}
                            goToOrder={this.goToOrder}
                            goToBOL={this.goToBOL}
                            inventory_source_id={this.state.inventory_source_id}
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export class Map2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened_marker: false
        };
    }

    onToggleOpen = function (order_id) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.scrollTo(0, 200);
        }
        if (this.state.opened_marker == order_id) {
            order_id = false;
        }
        this.setState({
            opened_marker: order_id
        });
    }

    render() {
        return (
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{lat: 43.651890, lng: -79.381706}}
                options={{fullscreenControl: true, gestureHandling: 'greedy'}}
            >
                {this.props.markers.map(function (marker) {
                    var marker_id = 0;
                    if (!isNaN(marker.inventory_source_id) && marker.inventory_source_id != null) {
                        marker_id = marker.inventory_source_id
                    }
                    var marker_url = "../img/markers/spotlight-poi2_hdpi_" + marker_id + ".png";
                    var directions_url = "https://www.google.com/maps/dir/?api=1&destination=" + marker.address1 + ", " + marker.city + ", Ontario&layer=t";
                    var self = this;
                    return (
                        <Marker
                            position={{lat: marker.latitude, lng: marker.longitude}}
                            key={marker.order_id}
                            onClick={this.onToggleOpen.bind(this, marker.order_id)}
                            icon={{
                                url: marker_url,
                                anchor: new window.google.maps.Point(15, 32),
                                scaledSize: new window.google.maps.Size(27, 43)
                            }}
                        >
                            {this.state.opened_marker == marker.order_id && <InfoWindow key={marker.order_id}>
                                <div style={{fontSize: '14px'}}>
                                    <div className="h4 mb-1">{marker.address1}{(marker.address2 != null ? " " + marker.address2 : "")}, {marker.city}</div>
                                    <b>{marker.name}</b> | Tel: <a href={'tel:' + marker.phone}>{marker.phone}</a><br/>
                                    {this.props.markers.map(function (item) {
                                        var items = "";
                                        for (var num in item['items']) {
                                            var item2 = item['items'][num];
                                            items += "<div> &bull; " + item2.quantity + "x " + item2.name + "</div>";
                                        }
                                        if (marker.latitude == item.latitude && marker.longitude == item.longitude) {
                                            return (
                                                <div className="border-top pt-1 pb-1">
                                                    Ordered {item.date_ordered}<br/>
                                                    Delivery by {(item.inventory_source_id > 0 ? self.props.inventory_sources[item.inventory_source_id].name : "<Not Assigned>")}
                                                    <div className={(item.est_delivery_date == null ? "d-none" : "")}>Est. Delivery: {item.est_delivery_date}</div>
                                                    <div className="pt-2 pb-2" dangerouslySetInnerHTML={{__html: items}}></div>
                                                    <div className={(item.delivery_instructions != "" ? "text-warning border border-warning p-1 mb-2" : "d-none")}>
                                                        {item.delivery_instructions}
                                                    </div>
                                                    <div className={((item.notes != null && item.notes != "") ? "text-danger mb-2" : "d-none")}>
                                                        <small>NOTE:</small> {item.notes}
                                                    </div>
                                                    <div>
                                                        <div className={(item.type == "INV" ? "btn btn-xs btn-primary" : "d-none")}
                                                             onClick={self.props.goToOrder.bind(this, item.order_id)}>Order {item.order_id}</div>
                                                        <div className={(item.type == "BOL" ? "btn btn-xs btn-primary" : "d-none")}
                                                             onClick={self.props.goToBOL.bind(this, item.order_id)}>BOL {item.order_id}</div>
                                                        <a href={directions_url} target="_blank" className="btn btn-xs btn-info float-right"><i
                                                            className="fa fa-location-arrow"></i> Directions</a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </InfoWindow>}
                        </Marker>
                    )
                }, this)}
                {this.props.pings.map(function (marker) {
                    var marker_url = "../img/markers/spotlight-poi2_hdpi_" + marker.inventory_source_id + ".png";
                    var unknown_location = false;
                    if (isNaN(marker.latitude)) {
                        marker.latitude = "43.802620";
                        marker.longitude = "-79.619247";
                        unknown_location = true;
                    }
                    if (this.props.inventory_source_id == null || this.props.inventory_source_id == marker.staff_id) {
                        return (
                            <Marker
                                position={{lat: marker.latitude, lng: marker.longitude}}
                                key={marker.inventory_source_id}
                                onClick={this.onToggleOpen.bind(this, marker.inventory_source_id)}
                                icon={{
                                    url: marker_url,
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(14, 43),
                                    scaledSize: new window.google.maps.Size(27, 43)
                                }}
                                animation={window.google.maps.Animation.BOUNCE}
                            >
                                {this.state.opened_marker == marker.inventory_source_id && <InfoWindow key={marker.inventory_source_id}>
                                    <div style={{fontSize: '14px'}}>
                                        <div className={(unknown_location ? "text-danger h4 mb-o" : "d-none")}>(Unknown Location)</div>
                                        <div className="h4 mb-1">{this.props.inventory_sources[marker.inventory_source_id].name} Location</div>
                                        As of {marker.last_time}<br/>
                                        Accuracy: {marker.accuracy}m<br/>
                                        Speed: {marker.speed}
                                        {this.props.account_rep}
                                    </div>
                                </InfoWindow>}
                            </Marker>
                        )
                    }
                }, this)}
            </GoogleMap>
        );
    }
}

export const MapWithAMakredInfoWindow2 = withScriptjs(withGoogleMap(Map2))

export default (MapOrder);
