import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    Input
} from 'reactstrap';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {getProductsParent} from "../Products/ProductFunctions";

class AddCallNotes extends React.Component {
    responses = {
        1: { /// OP
            products: [6, 27, 23, 7, 8, 9, 11, 12, 13, 14, 4, 19, 25, 3, 46],
            observations: [
                {id: 1, text: "Listed", type: "checkbox", value: "Yes", result: {}},
                {id: 2, text: "Dft/Pkd", type: "radio", value: ["Draft", "Package", "Both"], result: {}},
                {id: 3, text: "Ongoing Feature", type: "checkbox", value: "Yes", result: {}},
                {id: 4, text: "In Drink Menu", type: "checkbox", value: "Yes", result: {}},
                {id: 5, text: "POS (posters/tent card, etc)", type: "checkbox", value: "Yes", result: {}},
            ]
        },
        2: { // TBS
            products: [6, 7, 8, 4, 19, 25, 3],
            observations: [
                {id: 1, text: "Inventory", type: "input", result: {}},
                {id: 2, text: "Discretional Display", type: "checkbox", value: "Yes", result: {}},
                {id: 3, text: "Price", type: "input", value: "Yes", result: {}}
            ]
        },
        3: { // LCBO
            products: [6, 27, 23, 7, 11, 12, 13, 14, 3, 46, 54],
            observations: [
                {id: 1, text: "Listed", type: "checkbox", value: "Yes", result: {}},
                {id: 2, text: "Fridge Placement", type: "checkbox", value: "Yes", result: {}},
                {id: 3, text: "Discretional Display", type: "checkbox", value: "Yes", result: {}},
                {id: 4, text: "Program (if applicable)", type: "checkbox", value: "Yes", result: {}},
                {id: 5, text: "Price", type: "input", value: "Yes", result: {}},
            ]
        },
        5: { // grocery
            products: [6, 7, 8, 4, 19, 25, 3],
            observations: [
                {id: 1, text: "Listed", type: "checkbox", value: "Yes", result: {}},
                {id: 2, text: "Fridge Placement", type: "checkbox", value: "Yes", result: {}},
                {id: 3, text: "Discretional Display", type: "checkbox", value: "Yes", result: {}},
                {id: 4, text: "Price", type: "input", result: {}},
            ]
        },
        6: { // agency
            products: [6, 27, 23, 7, 8, 9, 11, 12, 13, 14, 4, 19, 25, 3, 46],
            observations: [
                {id: 1, text: "Listed", type: "checkbox", value: "Yes", result: {}},
                {id: 2, text: "Inventory", type: "checkbox", value: "Yes", result: {}},
                {id: 3, text: "Discretional Display", type: "checkbox", value: "Yes", result: {}},
                {id: 4, text: "Price", type: "input", result: {}},
            ]
        },
        7: { // LCBO warehouse
            products: [],
            observations: []
        },
        8: { // NSLC
            products: [],
            observations: []
        }, 9: {
            products: [],
            observations: []
        }, 10: {
            products: [],
            observations: []
        },
    }

    constructor(props) {
        super(props);
        window.AddCallNotes = this;
        this.addPhoto = this.addPhoto.bind(this);

        this.state = {
            modal: false,
            start1: moment().subtract(30, 'm').toDate(),
            end1: new Date(),
            notes: "",
            reminders: [],
            call_id: false,
            photos: [],
            editable: true,
            processing: false,
            products: {},
            responses: this.responses
        };
    }

    componentDidMount() {
        console.log(this.props.account.account_type)
        var self = this;
        getProductsParent(function (products) {
            self.setState({products: products});
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trigger_note !== this.props.trigger_note && this.props.trigger_note != false && !isNaN(this.props.trigger_note)) {
            var state = {
                modal: true,
                start1: moment().subtract(30, 'm').toDate(),
                end1: new Date(),
                notes: "",
                reminders: [],
                editable: true,
                processing: false,
                call_id: this.props.trigger_note,
                responses: this.responses
            }
            this.setState(state);
            var self = this;
            axios.get(API_ROOT + '/account/' + this.props.account_id + '/call/' + this.props.trigger_note)
                .then(function (response) {
                    state['start1'] = moment(response.data['start']).toDate();
                    state['end1'] = moment(response.data['end']).toDate();
                    state['notes'] = response.data['notes'];
                    state['photos'] = response.data['photos'];
                    state['editable'] = response.data['editable'];
                    if (response.data['data'] != "" && response.data['data'] != null) {
                        state['responses'][self.props.account.account_type] = JSON.parse(response.data['data']);
                    } else {
                        //state['responses'] = [];
                    }
                    console.log(state['responses'])
                    state['processing'] = false;
                    var reminders = []
                    for (var i in response.data['reminders']) {
                        var reminder = response.data['reminders'][i];
                        reminders.push({
                            title: reminder['title'],
                            completed: reminder['completed'],
                            date: moment(reminder['date']).toDate(),
                        })
                    }
                    state['reminders'] = reminders;
                    self.setState(state);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            calendarEvents: [],
                            loading: false
                        });
                    }
                });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
        this.props.resetCall();
    };

    changeDate = (key, date) => {
        this.setState({
            [key]: date
        });
    }

    updateQuestion = (product_id, event) => {
        var responses = this.state.responses;
        var self = this;
        responses[this.props.account.account_type]['observations'].map(function (observation, key) {
            if (parseInt(observation.id) == parseInt(event.target.name)) {
                if (
                    responses[self.props.account.account_type]['observations'][key].type == "checkbox" &&
                    typeof responses[self.props.account.account_type]['observations'][key].result != "undefined" &&
                    responses[self.props.account.account_type]['observations'][key].result[product_id] == "Yes"
                ) {
                    responses[self.props.account.account_type]['observations'][key].result[product_id] = "No"
                } else {
                    responses[self.props.account.account_type]['observations'][key].result[product_id] = event.target.value;
                }
            }
        });
        this.setState({
            responses: responses
        });
    }

    addReminder = () => {
        var reminders = this.state.reminders;
        reminders.push({
            date: moment().add(1, 'd').toDate(),
            title: "",
            completed: 0
        });
        this.setState({
            reminders: reminders
        });
    };
    updateReminder = (reminder, type, data) => {
        var reminders = this.state.reminders;
        if (type == "delete") {
            reminders.splice(reminder)
            Swal("Are you sure?", "Please click 'Save' if you want to delete the reminder", "info");
        } else {
            reminders[reminder][type] = data;
        }
        this.setState({
            reminders: reminders
        });
    }

    changeDropdown = (question, brand, answer) => {
        var responses = this.state.responses;
        if (typeof responses[question] == "undefined") {
            responses[question] = {};
        }
        responses[question][brand] = answer;
        this.setState({
            responses: responses
        });
    }

    selectCallNotes = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    addPhoto = (call_photo_id) => {
        var photos = this.state.photos;
        photos.push(call_photo_id);
        this.setState({
            photos: photos
        });
    }

    deleteCallNote = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This note and all associated reminders will be deleted",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            if (typeof result.dismiss == 'undefined') {
                axios.delete(API_ROOT + '/account/' + this.props.account_id + "/call_notes/" + this.state.call_id)
                    .then(function (response) {
                        self.setState({
                            modal: false,
                            call_id: false,
                            start1: moment().subtract(30, 'm').toDate(),
                            end1: new Date(),
                            notes: "",
                            reminders: [],
                            editable: true,
                            processing: false,
                        });
                        Swal("Done", "Call Note Deleted", "success");
                        self.props.getCalls();
                    })
                    .catch(function (error) {
                        Swal("Error", error.response.data.Message, "error");
                    });
            }
        });
    };

    addCallNotes = (editable) => {
        var self = this;
        self.setState({
            processing: true
        });
        axios.defaults.withCredentials = true;
        var data = {...this.state};
        delete (data['products']);
        data['editable'] = editable;
        data['account_id'] = this.props.account_id;
        data['staff_id'] = localStorage.getItem('staff_id')
        data['start'] = moment.utc(this.state.start1).format("YYYY-MM-DD HH:mm:ss");
        data['end'] = moment.utc(this.state.end1).format("YYYY-MM-DD HH:mm:ss");
        data['responses'] = data['responses'][self.props.account.account_id];
        if (this.state.call_id > 0) {
            // edit it
            axios.put(API_ROOT + '/account/' + this.props.account_id + '/call_notes/' + this.state.call_id, data)
                .then(function (response) {
                    self.setState({
                        modal: false,
                        call_id: false,
                        start1: moment().subtract(30, 'm').toDate(),
                        end1: new Date(),
                        notes: "",
                        reminders: [],
                        editable: true,
                        processing: false,
                        responses: self.responses
                    });
                    Swal("Success!", "Call Note Saved", "success");
                    self.props.resetCall();
                    self.props.getCalls();
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        } else {
            // add it
            axios.post(API_ROOT + '/account/' + this.props.account_id + '/call_notes', data)
                .then(function (response) {
                    self.setState({
                        call_id: response.data.call_id,
                        processing: false
                    });
                    Swal("Success!", "Call Note Added", "success");
                    self.props.getCalls();
                })
                .catch(function (error) {
                    if (typeof error.response != "undefined") {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                processing: false
                            });
                        }
                    }
                });
        }
    };

    render() {
        return (
            <div className="float-right">
                <Button color="primary" size="xs" className="mb-2" onClick={this.toggle}><i className="fas fa-plus"></i> Call Notes</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <div className="modal-header">
                        <div className="title">
                            <div className="h3 mb-0">Add Call Notes</div>
                        </div>
                        <div className="float-right" onClick={() => this.toggle()}><i className="fas fa-times"></i></div>
                    </div>
                    <ModalBody>
                        <div className="text-bold h4 pb-0 mb-0 pt-2">Date & Time</div>
                        <div className="row">
                            <div className="col-md-6 text-center">
                                Start: <DatePicker
                                selected={this.state.start1}
                                wrapperClassName="border-left border-top border-right"
                                onChange={date => this.changeDate('start1', date)}
                                showTimeSelect
                                disabled={!this.state.editable}
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                            </div>
                            <div className="col-md-6 text-center">
                                &nbsp;
                                End: <DatePicker
                                selected={this.state.end1}
                                wrapperClassName="border-left border-top border-right"
                                onChange={date => this.changeDate('end1', date)}
                                showTimeSelect
                                disabled={!this.state.editable}
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                            </div>
                        </div>

                        <div className="text-bold h4 pb-0 mb-0 pt-2">Notes</div>
                        <Input type="textarea" name="notes" value={this.state.notes} disabled={!this.state.editable} onChange={(event) => this.selectCallNotes("notes", event)}/>
                        <div className="text-bold h4 pb-0 mb-0 pt-2">Observations</div>
                        <div style={{overflowX: "scroll"}}>
                            <table className="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <th></th>
                                    {this.props.account.account_type != 4 && typeof this.state.responses[this.props.account.account_type] != "undefined" && this.state.responses[this.props.account.account_type].observations.map(function (observation, key) {
                                        return (
                                            <th className="text-center">{observation.text}</th>
                                        )
                                    }, this)}
                                </tr>
                                </thead>
                                <tbody>
                                {typeof this.props.account.account_type != "undefined" && this.props.account.account_type > 0 && this.props.account.account_type != 4 && this.state.responses[this.props.account.account_type].products.map(function (product_id, key) {
                                    return (
                                        <tr>
                                            <td>{(typeof this.state.products[product_id] != "undefined" ? this.state.products[product_id].name : "?")}</td>
                                            {this.state.responses[this.props.account.account_type].observations.map(function (observation, key) {
                                                if (observation.type == "checkbox") {
                                                    return (
                                                        <td className="text-center">
                                                            <Input className="m-1" disabled={!this.state.editable} type="checkbox" name={observation.id} value={observation.value}
                                                                   checked={(typeof observation.result[product_id] != "undefined" && observation.result[product_id] == observation.value ? true : false)}
                                                                   onClick={(event) => this.updateQuestion(product_id, event)}/>
                                                        </td>
                                                    )
                                                } else if (observation.type == "input") {
                                                    return (
                                                        <td>
                                                            <Input className="m-0 text-right" disabled={!this.state.editable} size="sm" type="text" name={observation.id}
                                                                   value={(typeof observation.result[product_id] != "undefined" ? observation.result[product_id] : "")}
                                                                   onChange={(event) => this.updateQuestion(product_id, event)}/>
                                                        </td>
                                                    )
                                                } else if (observation.type == "radio") {
                                                    return (
                                                        <td>
                                                            <ButtonGroup>
                                                                <Button
                                                                    color={(typeof observation.result[product_id] != "undefined" && observation.result[product_id] == observation.value[0] ? "dark" : "light")}
                                                                    size="sm"
                                                                    disabled={!this.state.editable}
                                                                    name={observation.id}
                                                                    value={observation.value[0]}
                                                                    onClick={(event) => this.updateQuestion(product_id, event)}
                                                                    active={(observation.value[0] == observation.result)}
                                                                >
                                                                    {observation.value[0]}
                                                                </Button>
                                                                <Button
                                                                    color={(typeof observation.result[product_id] != "undefined" && observation.result[product_id] == observation.value[1] ? "dark" : "light")}
                                                                    size="sm"
                                                                    disabled={!this.state.editable}
                                                                    name={observation.id}
                                                                    value={observation.value[1]}
                                                                    onClick={(event) => this.updateQuestion(product_id, event)}
                                                                    active={(observation.value[1] == observation.result)}
                                                                >
                                                                    {observation.value[1]}
                                                                </Button>
                                                                <Button
                                                                    color={(typeof observation.result[product_id] != "undefined" && observation.result[product_id] == observation.value[2] ? "dark" : "light")}
                                                                    size="sm"
                                                                    disabled={!this.state.editable}
                                                                    name={observation.id}
                                                                    value={observation.value[2]}
                                                                    onClick={(event) => this.updateQuestion(product_id, event)}
                                                                    active={(observation.value[2] == observation.result)}
                                                                >
                                                                    {observation.value[2]}
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    )
                                                }
                                            }, this)}
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-bold h4 pb-0 mb-0">Photos</div>
                        <div>
                            {this.state.photos.map(function (photo, key) {
                                return (
                                    <a href={"call_photos/" + this.state.photos[key] + ".jpg"} data-lity>
                                        <img src={"call_photos/" + this.state.photos[key] + "_sm.jpg"} className="pr-2 pb-2"></img>
                                    </a>
                                )
                            }, this)}
                            <div className={(this.state.call_id < 1 ? "text-danger" : "d-none")}>
                                <i className="fas fa-exclamation-triangle"></i> Please click "Save as DRAFT" first to add photos
                            </div>
                            <div className={(this.state.call_id > 0 && this.state.editable ? "" : "d-none")}>
                                <Upload account_id={this.props.account_id} call_id={this.state.call_id} photos={this.state.photos} addPhoto={this.addPhoto}/>
                            </div>
                        </div>

                        <div className="text-bold h4 pb-0 mb-0 pt-2">Reminders</div>
                        {this.state.reminders.map(function (reminder, key) {
                            return (
                                <div className="row pt-2">
                                    <div className="pl-3">
                                        <DatePicker
                                            selected={this.state.reminders[key].date}
                                            onChange={date => this.updateReminder(key, 'date', date)}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            wrapperClassName="border-left border-top border-right"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="col">
                                        <input type="text" placeholder="Reminder Details" className="form-control" value={reminder.title}
                                               onChange={(event) => this.updateReminder(key, "title", event.target.value)}/>
                                    </div>
                                    <div className="col-1 text-center">
                                        <label>
                                            <div className="small" style={{marginTop: "-5px"}}>Completed?</div>
                                            <input type="checkbox" className="form-check-label" value="1" title="Mark as Completed" checked={reminder.completed}
                                                   onClick={(event) => this.updateReminder(key, "completed", !reminder.completed)}/>
                                        </label>
                                    </div>
                                    <div className="col-2 text-right">
                                        <input type="button" className="btn btn-danger btn-sm" value="Delete" onClick={(event) => this.updateReminder(key, "delete", null)}/>
                                    </div>
                                </div>
                            )
                        }, this)}
                        <div className="btn btn-primary btn-sm" onClick={this.addReminder}><i className="fas fa-plus"></i> New Reminder</div>
                    </ModalBody>
                    <ModalFooter>
                        <div className={(this.state.call_id > 0 ? "btn btn-danger mr-auto" : "d-none")} onClick={this.deleteCallNote}>Delete</div>
                        <div className={(this.state.editable ? "btn btn-warning" : "d-none")} onClick={() => this.addCallNotes(1)}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save as DRAFT
                        </div>
                        <div className="btn btn-success" onClick={() => this.addCallNotes(0)}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>
                            {(this.state.editable ? "Save as COMPLETE" : "Save Reminder Changes")}
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    };
}

const Upload = (props) => {
    const getUploadParams = ({file, meta}) => {
        const url = API_ROOT + '/account/' + props.account_id + '/call_notes/' + props.call_id + '/photo';
        const body = new FormData();
        body.append('image', file);
        return {url, body}
    };

    const handleChangeStatus = ({meta, remove, xhr}, status) => {
        if (status === 'done') {
            var response = JSON.parse(xhr.response);
            var call_photo_id = response.call_photo_id;
            props.addPhoto(call_photo_id)
        } else if (status === 'headers_received') {
            remove()
        } else if (status === 'aborted') {
            Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'JPG or PNG images only' : "Upload a Photo (drag n' drop or click)")}
                styles={{
                    inputLabel: {fontSize: "14px", fontWeight: "normal"},
                    dropzone: {height: 100, minHeight: 75, border: "1px dashed #ccc",},
                    dropzoneActive: {borderColor: 'green'},
                }}
            />
        </React.Fragment>
    )
};

export default (AddCallNotes);
