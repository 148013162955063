import React, {Component} from 'react';
import {
    Button,
    Modal,
    Col,
    CardBody,
    Card,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import AddCard from '../Accounts/AddCard';


class ManageCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            name: "",
            number: "",
            last_four: "",
            expiry: "",
            issuer: "",
            card_id: false,
            loading: true
        };
        this.getSavedCC = this.getSavedCC.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.account_id !== this.props.account_id) {
            this.getSavedCC()
        }
    }

    componentDidMount() {
        this.getSavedCC()
    }

    deleteCard = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This card will be deleted from the account",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete now',
            cancelButtonText: 'No',
        }).then((result) => {
            axios.delete(API_ROOT + '/account/' + this.props.account_id + '/card')
                .then(function (response) {
                    self.setState({
                        loading: true
                    });
                    self.getSavedCC()
                })
                .catch(function (error) {
                    Swal("Error", error.response.data.Message, "error");
                });
        });
    };

    showCard = () => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'View Full Credit Card Number',
            html: '<div>Enter the credit card decryption pin:</div><input type="password" value="" id="cc_pass" class="form-control">',
            showCancelButton: true,
            confirmButtonText: 'Show',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            axios.put(API_ROOT + '/account/' + this.props.account_id + '/card', {password: document.getElementById("cc_pass").value})
                .then(function (response) {
                    self.setState({
                        name: response.data.name,
                        last_four: response.data.last_four,
                        number: response.data.number,
                        expiry: response.data.expiry_month + "/" + response.data.expiry_year,
                        issuer: response.data.card_type,
                        card_id: response.data.card_id,
                        loading: false
                    });
                    document.getElementById("cc_pass").value = ""
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        });
    };

    getSavedCC() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.props.account_id + '/card')
            .then(function (response) {
                self.setState({
                    name: response.data.name,
                    last_four: response.data.last_four,
                    number: response.data.number,
                    expiry: response.data.expiry_month + "/" + response.data.expiry_year,
                    issuer: response.data.card_type,
                    card_id: response.data.card_id,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };


    render() {
        return (
            <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                <CardBody>
                    <div className="lead bb">{(this.state.name == "" ? 'No Saved Card' : '1 Saved Card')}
                        <div className="float-right">
                            <AddCard account_id={this.props.account_id} getSavedCC={this.getSavedCC}/>
                        </div>
                    </div>
                    <div className={(this.state.name == "" ? "d-none" : "row pt-2")}>
                        <Col lg={1}><i className={"fab fa-lg fa-cc-" + this.state.issuer}></i></Col>
                        <Col>
                            <div className={(this.state.number == "" ? "" : "d-none")}>****{this.state.last_four} <Button size="xs" color="primary"
                                                                                                                          onClick={this.showCard}>Show</Button></div>
                            <div className={(this.state.number == "" ? "d-none" : "")}>{this.state.number}</div>
                        </Col>
                        <Col>Exp. {this.state.expiry}</Col>
                        <Col>{this.state.name}</Col>
                        <Col className="text-right"><Button size="xs" color="danger" onClick={this.deleteCard}><i className="far fa-trash-alt"></i></Button></Col>
                    </div>
                </CardBody>
            </Card>
        );
    };
}

export default (ManageCard);
