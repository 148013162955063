import React, {useEffect} from "react";
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class TbsList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            transactions: [],
            loading: false,
            // default search settings
            category: null,
            product_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            category_name: "All",
            product_name: "All",
            // data
            categories: {
                9: "Brewer Shipment",
                18: "Brewer Shipment Adjustment",
                20: "Counter Sale",
                40: "SOP Sale",
                50: "LCBO Sale/Deliver",
                55: "Agency Sale/Delivery",
                60: "Licensee Sale",
                12: "SOP Return",
                13: "LCBO Return",
                14: "Licensee Return",
                17: "Retail Partner Return",
                71: "FG Return to Brewer",
                74: "FG Damages - Brewer",
                10: "FG Transfer In",
                70: "FG Transfer Out",
                15: "Repack In",
                72: "Repack Out",
                22: "Variance FG",
                73: "FG Damage - BRI",
                75: "Insurable Loss",
                77: "FG Write-Off - BRI",
                99: "FG Closing Inventory",
                82: "MT Returns"
            },
            products: [],
            accounts: [],
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
            detail: "Full",
            limit: 500,
            num_rows: 0,
            first_load: true
        };
    }

    componentDidMount() {
        document.title = "TBS Log | Equals Brewing";
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/products/tbs')
            .then(function (response) {
                self.setState({
                    products: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/accounts/tbs')
            .then(function (response) {
                self.setState({
                    accounts: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate,
                limit: 500
            });
        }
    }

    changeCategory = (category) => {
        if (category == null) {
            var category_name = "All"
        } else {
            var category_name = this.state.categories[category];
        }
        this.setState({
            category: category,
            category_name: category_name,
            limit: 500
        });
    };
    changeProduct = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name,
            limit: 500
        });
    };
    changeDetail = (detail) => {
        this.setState({
            detail: detail,
            limit: 500
        });
    }

    loadMore = event => {
        this.setState({
            limit: this.state.limit + 500,
        }, () => {
            this.getTransactions();
        });
    };

    downloading = event => {
        Swal("Preparing Download", "Please wait, it might take a minute. ", "info");
        const onBlur = () => {
            Swal.close();
        };
        window.addEventListener("blur", onBlur);
    };

    getTransactions() {
        this.setState({
            loading: true,
            first_load: false
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/tbs/list/?category=' + self.state.category + "&product_id=" + self.state.product_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&limit=" + self.state.limit + "&detail=" + self.state.detail)
            .then(function (response) {
                self.setState({
                    transactions: response.data.transactions,
                    num_rows: response.data.num,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    TBS Report
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color={((String(this.state.category) == "null") ? "primary" : "warning")}
                                        style={{width: 'auto'}}>
                            Category: <b>{this.state.category_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeCategory(null, null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.categories).map((category, key1) =>
                                <DropdownItem key={key1} onClick={() => this.changeCategory(category)}>{this.state.categories[category]}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color={(String(this.state.product_id) == "null" ? "primary" : "warning")} style={{width: 'auto'}}>
                            Product: {this.state.product_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.products).map((products_id) =>
                                <DropdownItem key={products_id} onClick={() => this.changeProduct(products_id)}>{this.state.products[products_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="btn btn-info" onClick={() => this.getTransactions()}>Search</div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className={(this.state.first_load ? "" : "d-none")}>Select the search criteria and click "Search"</div>
                            <div className={(this.state.first_load ? "d-none" : "card-title")}>
                                {(this.state.num_rows).toLocaleString()} Matching Transactions
                                <a href={API_ROOT + '/tbs/list/?category=' + this.state.category + "&product_id=" + this.state.product_id + "&startDate=" + this.state.startDate + "&endDate=" + this.state.endDate + "&limit=" + this.state.limit + "&detail=" + this.state.detail + "&export=true"}
                                   className="btn btn-secondary float-right" onClick={this.downloading}><i className="fas fa-download mr-1"></i> Export Transactions</a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 ? "table table-sm table-bordered small" : "d-none")}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Trans. Date</th>
                                        <th>Category</th>
                                        <th>Source Site #</th>
                                        <th>Source Site Name</th>
                                        <th>Source Site Address</th>
                                        <th>Source Site City</th>
                                        <th>Delivery Site #</th>
                                        <th>Delivery Site Name</th>
                                        <th>Delivery Site Address</th>
                                        <th>Delivery Site City</th>
                                        <th>Prod. Article #</th>
                                        <th>Prod. Name</th>
                                        <th>Prod. Containers</th>
                                        <th>Customer</th>
                                        <th>Cust. Group</th>
                                        <th>Price Group</th>
                                        <th>Order #</th>
                                        <th>Posting Date</th>
                                        <th>Delivery Date</th>
                                        <th>Reason Code</th>
                                        <th className="text-right">Qty</th>
                                        <th className="text-right">Price</th>
                                        <th className="text-right">Tax</th>
                                        <th className="text-right">Deposit</th>
                                        <th className="text-right">Total</th>
                                        <th className="text-right">HL</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (item, key) {
                                        return (
                                            <tr key={key}>
                                                <td>{item.td}</td>
                                                <td>{this.state.categories[item.tt]}</td>
                                                <td>{item.ss}</td>
                                                <td>{(typeof this.state.accounts[item.ss] != "undefined" ? this.state.accounts[item.ss].name : "")}</td>
                                                <td>{(typeof this.state.accounts[item.ss] != "undefined" ? this.state.accounts[item.ss].address1 : "")}</td>
                                                <td>{(typeof this.state.accounts[item.ss] != "undefined" ? this.state.accounts[item.ss].city : "")}</td>
                                                <td>{item.ds}</td>
                                                <td>{(item.ds == '' ? '' : (typeof this.state.accounts[item.ds] != "undefined" ? this.state.accounts[item.ds].name : ""))}</td>
                                                <td>{(item.ds == '' ? '' : (typeof this.state.accounts[item.ds] != "undefined" ? this.state.accounts[item.ds].address1 : ""))}</td>
                                                <td>{(item.ds == '' ? '' : (typeof this.state.accounts[item.ds] != "undefined" ? this.state.accounts[item.ds].city : ""))}</td>
                                                <td>{item.an}</td>
                                                <td>{(typeof this.state.products[item.an] != "undefined" ? this.state.products[item.an].name : "")}</td>
                                                <td>{(typeof this.state.products[item.an] != "undefined" ? this.state.products[item.an].numContainers : "")}</td>
                                                <td>{item.ci}</td>
                                                <td>{item.cg}</td>
                                                <td>{item.pg}</td>
                                                <td>{item.onum}</td>
                                                <td>{item.pd}</td>
                                                <td>{item.dd}</td>
                                                <td>{item.rc}</td>
                                                <td className="text-right">{item.q}</td>
                                                <td className="text-right">{(typeof item.ca == "number" ? "$" + item.ca.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.h == "number" ? "$" + item.h.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.d == "number" ? "$" + item.d.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.t == "number" ? "$" + item.t.toLocaleString() : "")}</td>
                                                <td className="text-right">{this.state.products[item.an].volumeML * this.state.products[item.an].numContainers * item.q / 100000}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                                <div className={(this.state.num_rows > this.state.transactions.length ? "text-center p-1" : "d-none")} onClick={this.loadMore}><Button
                                    color="primary">
                                    <i className="fas fa-angle-down"></i> Load More</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (TbsList);
