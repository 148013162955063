import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    UncontrolledButtonDropdown,
    Button,
    Modal,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Col,
    Card,
    CardBody,
    Label,
    FormGroup,
    Row,
    Alert
} from 'reactstrap';
import {getOwners} from '../Owners/OwnerFunctions.js';

import {Link} from 'react-router-dom';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ListsOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            new: {
                name: "",
                payment_instructions: "",
                legal_text: "",
                address: "",
                email: "",
                merchant: "None",
                merch_dev: "",
                merch_prod: "",
                ddvp_user: "",
                ddvp_pass: "",
                grocery_user: "",
                grocery_pass: ""
            },
            merchants: [
                "None",
                "Stripe"
            ],
            companies: []
        };
    }

    componentDidMount() {
        document.title = "Companies | Equals Brewing";
        this.loadData();
    }

    loadData() {
        var self = this;
        axios.get(`${API_ROOT}/owners/detail/`, {withCredentials: true})
            .then(function (ajax_response) {
                self.setState(
                    {companies: ajax_response.data, loading: false}
                );
            })
    }

    onchangeEdit = (name, id) => event => {
        var list = this.state.list;
        list[name][id][event.target.name] = event.target.value;
        this.setState({
            list: list
        });
    };

    onchangeNew = (name) => event => {
        var list = this.state.new;
        list[name][event.target.name] = event.target.value;
        this.setState({
            product: list
        });
    };

    saveChanges = (name) => {
        var error = "";
        var self = this;
        Object.keys(this.state.list[name]).map(function (entry) {
            if (self.state.list[name][entry].name == "") {
                error = "Please ensure no fields are blank";
            }
        });
        if (error == "") {
            var self = this;
            self.setState({loading: true});
            axios.defaults.withCredentials = true;
            axios.put(API_ROOT + '/' + name, {data: self.state.list[name]})
                .then(function (response) {
                    self.loadData();
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", "It didn't work", "error");
                });
        } else {
            Swal("Error", error, "error");
        }
    };

    addItem = (name) => {
        if (this.state.new[name] == "") {
            Swal("Error", "Field can't be blank", "error");
        } else {
            var self = this;
            self.setState({loading: true});
            axios.defaults.withCredentials = true;
            axios.post(API_ROOT + '/' + name, {data: self.state.new[name]})
                .then(function (response) {
                    var new_a = self.state.new;
                    new_a[name].name = "";
                    new_a[name].hectilitre = 0;
                    new_a[name].email = "";
                    new_a[name].address = 0;
                    self.setState({
                        new: new_a
                    });
                    self.loadData();
                    toast.success("Item Added", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                })
                .catch(function (error) {
                    Swal("Error", "It didn't work", "error");
                });
        }
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Companies
                    </div>
                </div>
                <div className={(typeof this.props.match.params.list_name != "undefined" ? "text-bold text-center pb-3" : "d-none")}>
                    <Link to="../../lists">Show All Lists</Link>
                </div>
                <Row className="justify-content-md-center">
                    {Object.keys(this.state.companies).map(function (company_id, key) {
                        var company = this.state.companies[company_id];
                        return (
                            <Col key={key} style={{"minWidth": "250px", "maxWidth": "500px"}}>
                                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label sm={4}>Company Name*</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="name" value={company.name} onChange={(event) => this.updateField("name", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Payment Instructions</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="payment_instructions" value={company.payment_instructions}
                                                       onChange={(event) => this.updateField("payment_instructions", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Legal Text</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="legal_text" value={company.legal_text} onChange={(event) => this.updateField("legal_text", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Email</Label>
                                            <Col sm={8}>
                                                <Input type="email" name="email" value={company.email} onChange={(event) => this.updateField("email", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Mailing Address</Label>
                                            <Col sm={8}>
                                                <Input type="email" name="address" value={company.address} onChange={(event) => this.updateField("address", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Merchant</Label>
                                            <Col sm={8}>
                                                <UncontrolledButtonDropdown size="xs">
                                                    <DropdownToggle size="xs" color="secondary" caret>
                                                        {company.merchant}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.merchants).map(function (merchant, key) {
                                                            return (
                                                                <DropdownItem key={merchant} onClick={() => this.changeRep(merchant)}>{merchant}</DropdownItem>
                                                            )
                                                        }, this)}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </Col>
                                        </FormGroup>
                                        <div className={(company.merchant != "Stripe" ? "d-none" : "")}>
                                            <FormGroup row>
                                                <Label sm={4}>{company.merchant} Dev Key</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="merch_dev" value={company.merch_dev} onChange={(event) => this.updateField("merch_dev", event)}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={4}>{company.merchant} Prod Key</Label>
                                                <Col sm={8}>
                                                    <Input type="text" name="merch_prod" value={company.merch_prod} onChange={(event) => this.updateField("merch_prod", event)}/>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                        <FormGroup row>
                                            <Label sm={4}>DDVP User</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="ddvp_user" value={company.ddvp_user} onChange={(event) => this.updateField("ddvp_user", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>DDVP Pass</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="ddvp_pass" value={company.ddvp_pass} onChange={(event) => this.updateField("ddvp_pass", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Grocery User</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="grocery_user" value={company.grocery_user} onChange={(event) => this.updateField("grocery_user", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4}>Grocery Pass</Label>
                                            <Col sm={8}>
                                                <Input type="text" name="grocery_pass" value={company.grocery_pass} onChange={(event) => this.updateField("grocery_pass", event)}/>
                                            </Col>
                                        </FormGroup>
                                        <Button color="primary" size="sm" className="mt-1" onClick={this.saveChanges.bind(this, name)}>Save Changes</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    }, this)}
                </Row>
                <ToastContainer/>
            </ContentWrapper>
        );
    }
}

export default (ListsOverview);