import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, Row, Col, Form, FormGroup, Label, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import {getInventorySources, getProducts} from '../Inventory/InventoryFunctions.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class CreateBOL extends React.Component {

    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            account: {
                name: '',
                city: '',
                address1: '',
                province: '',
                delivery_instructions: '',
            },
            products: {},
            notes: '',
            loading: true,
            processing: false,
            email_statuses: {0: "No", 1: "Yes"},
            email_status_print: "No",
            email_status: 0,
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery", 3: "Delivered", 9: "Held - Pending Approval"},
            delivery_status_print: "Processing",
            delivery_status: 0,
            inventory_sources: [],
            inventory_source_id: 0,
            inventory_source_print: "",
            po: "",
            bol_id: "",
            date: moment(),
        };
    }

    componentDidMount() {
        document.title = "Create BOL | Equals Brewing";
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + this.props.match.params.account_id)
            .then(function (response) {
                self.setState({loading: false});
                self.setState({account: response.data});
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
        getInventorySources(null, function (inventory_sources) {
            inventory_sources[0] = {
                'name': '---',
            };
            getProducts(function (products) {
                for (const product_id of Object.keys(products)) {
                    products[product_id].ordered = 0
                    /*if (products[product_id].product_category_id != 2) {
                        delete products[product_id];
                    }*/
                }
                self.setState({
                    inventory_sources: inventory_sources,
                    inventory_source_print: "Unassigned",
                    products: products
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        event.preventDefault();
        console.log(picker);
        if (event.type == "apply") {
            this.setState({
                date: picker.startDate,
            });
        }
    }

    textChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    // Changing email status
    changeEmailStatus = (email_status) => {
        var params = {
            email_status: email_status,
            email_status_print: this.state.email_statuses[email_status]
        };
        this.setState(params);
    };

    // Changing inventory source
    changeInventorySource = (inventory_source_id) => {
        var params = {
            inventory_source_id: inventory_source_id,
            inventory_source_print: this.state.inventory_sources[inventory_source_id].name,
        };
        this.setState(params);
    };

    // Changing delivery status
    changeDeliveryStatus = (delivery_status) => {
        var params = {
            delivery_status: delivery_status,
            delivery_status_print: this.state.delivery_statuses[delivery_status]
        };
        this.setState(params);
    };

    // Edit Instructions
    ChangeInstructions = (event) => {
        console.log(event.target.id)
        var current_data = this.state.account;
        current_data['delivery_instructions'] = event.target.value;
        this.setState({account: current_data});
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/account/' + this.state.account.account_id, {data: {delivery_instructions: event.target.value}})
    };

    // Add/Remove/Eit Items
    IncrementItem = (key) => {
        var old_products_list = this.state.products;
        old_products_list[key].ordered = old_products_list[key].ordered + 1;
        this.setState({products: old_products_list});
    };
    DecreaseItem = (key) => {
        var old_products_list = this.state.products;
        old_products_list[key].ordered = old_products_list[key].ordered - 1;
        this.setState({products: old_products_list});
    };
    ChangeItem = (key, event) => {
        var old_products_list = this.state.products;
        if (!isNaN(event.target.value)) {
            old_products_list[key].ordered = event.target.value;
            this.setState({products: old_products_list});
        }
    };

    submitOrder = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/bol/create/' + this.state.account.account_id, self.state)
            .then(function (response) {
                localStorage.setItem('show_bol_success' + response.data.bol_id, true);
                self.props.history.push("/bol/" + response.data.bol_id);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        processing: false
                    });
                }
            });
    };

    handleFocus = function (event) {
        if (event.target.value == "0") {
            event.target.value = "";
        }
        event.target.select();
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="/accounts">Accounts</Link>&nbsp;/&nbsp;<Link to={"/account/" + this.state.account.account_id}>{this.state.account.name}</Link>&nbsp;/ Create BOL
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className="card-body">
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Account</Label>
                                        <div>
                                            <Link to={"/account/" + this.state.account.account_id}>{this.state.account.name}</Link><br></br>
                                            {this.state.account.address1}, {this.state.account.city}, {this.state.account.province}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">BOL # <span className="text-muted font-weight-normal">(optional)</span></Label>
                                        <Input type="number" value={this.state.bol_id} placeholder="Default: Auto-Generate" id="bol_id" onChange={this.textChange}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Date</Label>
                                        <div>
                                            <DateRangePicker
                                                singleDatePicker={true}
                                                date={this.state.date}
                                                maxDate={moment()}
                                                onEvent={this.updateDates}
                                                autoUpdateInput={true}
                                            >
                                                <div className="btn btn-secondary">
                                                    {this.state.date.format('MMM. D, YYYY')} <span className="fas fa-caret-down fa-sm"></span>
                                                </div>
                                            </DateRangePicker>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">PO Number <span className="text-muted font-weight-normal">(optional)</span></Label>
                                        <Input type="text" id="po" value={this.state.po} onChange={this.textChange}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Delivery By</Label>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.inventory_source_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                                        return (
                                                            <DropdownItem key={inventory_source_id}
                                                                          onClick={() => this.changeInventorySource(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Delivery Status</Label>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.delivery_status_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.delivery_statuses).map(function (delivery_status, key) {
                                                        return (
                                                            <DropdownItem key={delivery_status}
                                                                          onClick={() => this.changeDeliveryStatus(delivery_status)}>{this.state.delivery_statuses[delivery_status]}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Delivery Instructions / Notes <span
                                            className="text-muted font-weight-normal">(visible on BOL)</span></Label>
                                        <Input type="textarea" id="delivery_instructions" onChange={this.ChangeInstructions.bind(this)}
                                               value={this.state.account.delivery_instructions}/>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Internal Notes <span className="text-muted font-weight-normal">(NOT visible on BOL)</span></Label>
                                        <Input type="textarea" id="notes" onChange={this.textChange} value={this.state.notes}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-0">Email BOL to Customer</Label>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.email_status_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.email_statuses).map(function (email_status, key) {
                                                        return (
                                                            <DropdownItem key={email_status}
                                                                          onClick={() => this.changeEmailStatus(email_status)}>{this.state.email_statuses[email_status]}</DropdownItem>
                                                        )
                                                    }, this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr className="bg-gray-lighter">
                                    <th>Product</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(this.state.products).map((product_id) =>
                                    <tr key={product_id}>
                                        <td>
                                            <img src={"https://order.equalsbrewing.com/img/products/" + product_id + "_sm.png"} style={{width: "50px", height: "50px"}}
                                                 className="mr-1"></img>
                                            {this.state.products[product_id].name}
                                        </td>
                                        <td>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend d-none d-sm-block" style={{cursor: "pointer"}}
                                                     onClick={this.DecreaseItem.bind(this, product_id)}>
                                                    <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">-</span>
                                                </div>
                                                <input type="tel" className="form-control" value={this.state.products[product_id].ordered} onFocus={this.handleFocus}
                                                       onChange={this.ChangeItem.bind(this, product_id)} style={{maxWidth: '60px'}}></input>
                                                <div className="input-group-append d-none d-sm-block" style={{cursor: "pointer"}}
                                                     onClick={this.IncrementItem.bind(this, product_id)}>
                                                    <span className="input-group-text font-weight-bold" id="inputGroup-sizing-sm">+</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <div className="text-right float-right" style={{'maxWidth': '500px'}}>
                                <Button size="lg" color="primary" className="ml-4 mt-3" disabled={this.state.processing} onClick={this.submitOrder}><i
                                    className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Create BOL</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (CreateBOL);
